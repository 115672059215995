<template>
  <div>
    <port-mapping-header :hotel-name="hotelName" v-if="parseInt($attrs['data-index']) !== 0" />
    <table class="port-mapping-table core-switch-table" :data-index="$attrs['data-index']">
      <thead>
        <tr class="switch-details">
          <td class="switch-name-label">Name</td>
          <td class="switch-name-column">{{ hardwareSwitch.name }}</td>
          <td class="switch-model">Model: {{ hardwareSwitch.model }}</td>
          <td colspan="2" class="switch-ip">IP:{{ hardwareSwitch.ipAddress }}</td>
        </tr>
        <tr class="port-details">
          <td class="port-number-heading">Port</td>
          <td colspan="2">Port description/name</td>
          <td>Untagged</td>
          <td>Tagged</td>
        </tr>
      </thead>
      <tbody>
        <td v-if="hasBeenContinued" class="continued" colspan="5">Continued from previous page</td>
        <tr v-for="(port, index) in ports" :key="index">
          <td class="port-number">{{ port.name }}</td>
          <td colspan="2" class="port-description">{{ port.description }}</td>
          <td>{{ port.untagged }}</td>
          <td class="tagged">{{ port.tagged }}</td>
        </tr>
        <tr>
          <td v-if="toBeContinued" class="continued" colspan="5">Continued on next page</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import PortMappingHeader from "@/components/PortMappingHeader";
@Component({
  props: {
    hardwareSwitch: Object,
    hotelName: String,
    ports: Array,
    toBeContinued: Boolean,
    hasBeenContinued: { type: Boolean, default: false }
  },
  components: {
    PortMappingHeader
  }
})
export default class SwitchTable extends Vue {}
</script>

<style></style>
