<template>
  <table class="port-mapping-page-header">
    <tr>
      <td>
        Reivernet | Hotel networks that mean business
      </td>
      <td class="port-mapping-header-right">
        Port Mapping documentation for<br />
        {{ hotelName }}
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    hotelName: String
  },
  name: "PortMappingHeader"
};
</script>

<style scoped></style>
