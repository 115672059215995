<template>
  <div>
    <img src="../assets/portMappingCover.jpg" class="port-mapping-cover-image" />
    <p class="port-mapping-cover-doc-heading">
      <span>Reivernet Documentation for<br /></span>
      <span class="port-mapping-cover-hotel-name">{{ hotelName }}</span>
    </p>

    <p class="port-mapping-cover-text">
      Prepared By:<br /><br />
      {{ userName }}<br />
      <br />
      {{ currentDate }}<br />Version 1.0
    </p>

    <port-mapping-header :hotel-name="hotelName" />
    <h2 class="switch-section-heading">Core Switches</h2>
    <template v-for="(coreSwitch, index) in coreSwitches">
      <switch-table
        :hotelName="hotelName"
        v-for="(portSubset, page) in portPages(coreSwitch.ports)"
        :key="`core-${index}-${page}`"
        :hardware-switch="coreSwitch"
        :to-be-continued="page < portPages(coreSwitch.ports).length - 1"
        :has-been-continued="page > 0"
        :ports="portSubset"
        :data-index="index + page"
      ></switch-table>
    </template>

    <port-mapping-header :hotel-name="hotelName" />
    <h2 class="switch-section-heading">Edge/Access Switches</h2>
    <template v-for="(edgeSwitch, index) in edgeSwitches">
      <switch-table
        :hotelName="hotelName"
        v-for="(portSubset, page) in portPages(edgeSwitch.ports)"
        :key="`edge-${index}-${page}`"
        :hardware-switch="edgeSwitch"
        :to-be-continued="page < portPages(edgeSwitch.ports).length - 1"
        :has-been-continued="page > 0"
        :ports="portSubset"
        :data-index="index + page"
      ></switch-table>
    </template>

    <img src="../assets/portMappingBack.jpg" class="port-mapping-back-image" />
    <table class="port-mapping-office-table">
      <tr>
        <td colspan="2" class="port-mapping-reivernet-offices">
          REIVERNET OFFICES
        </td>
      </tr>
      <tr>
        <td>
          <span class="port-mapping-country-heading">AUSTRALIA</span><br />
          Email: australia@reivernet.com<br />
          Tel: +61 2 9924 0288<br />
          Suite 1, 115 Sailors Bay Road, <br />
          Northbridge, NSW 2063<br />
          Australia<br />
        </td>
        <td>
          <span class="port-mapping-country-heading">SINGAPORE</span><br />
          Email: singapore@reivernet.com<br />
          Tel: +65 1 800 723 1439<br />
          1 Scotts Road, #23-06 Shaw Centre<br />
          Singapore 228208<br />
        </td>
      </tr>
      <tr>
        <td>
          <span class="port-mapping-country-heading">FIJI</span><br />
          Email: fiji@reivernet.com<br />
          Tel: +64 9 573 5190<br />
          Provident Plaza, Level 3,<br />
          Module 2, 33 Ellery Street<br />
          Fiji<br />
        </td>
        <td>
          <span class="port-mapping-country-heading">UNITED ARAB EMIRATES</span><br />
          Email: uae@reivernet.com<br />
          Tel: +971 4 424 3313<br />
          License Number 17203<br />
          Reivernet FZ-LLC <br />
          Office 165-166, Building No. 17<br />
          Dubai Internet City, Dubai, UAE<br />
        </td>
      </tr>
      <tr>
        <td>
          <span class="port-mapping-country-heading">NEW ZEALAND</span><br />
          Email: newzealand@reivernet.com<br />
          Tel: +64 9 573 5190<br />
          Unit G, 26 Vestey Drive<br />
          Mt Wellington, Auckland 1060<br />
          New Zealand<br />
        </td>
      </tr>
    </table>

    <table class="back-page-footer">
      <tr>
        <td class="back-page-footer-website">www.reivernet.com</td>
        <td class="back-page-footer-logo">
          <img src="../assets/reivernetLogo.jpg" style="height:1.4cm" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import SwitchTable from "@/components/SwitchTable.vue";
import { ref, computed } from "@vue/composition-api";
import "../assets/portMappingDocument.css";
import PortMappingHeader from "@/components/PortMappingHeader.vue";
import { defineComponent } from "@vue/composition-api";
import moment from "moment";

export default defineComponent({
  props: {
    siteDns: {
      type: [String],
      required: true
    }
  },
  components: {
    SwitchTable,
    PortMappingHeader
  },
  name: "PortMappingDocument",
  setup(props, { root }) {
    const coreSwitches = ref([]);
    const edgeSwitches = ref([]);
    const loading = ref(false);
    const hotel = ref({
      id: null,
      name: "",
      dnsName: ""
    });

    const currentDate = moment().format("DD/MM/YYYY");

    const hotelName = computed(() => {
      if (!Object.prototype.hasOwnProperty.call(hotel.value, "name")) {
        return "";
      } else {
        return hotel.value.name;
      }
    });

    const userName = computed(() => {
      const userDataString = localStorage.getItem("user");
      if (
        userDataString !== "" &&
        userDataString !== "undefined" &&
        userDataString !== undefined &&
        userDataString !== null
      ) {
        const userData = JSON.parse(userDataString);
        return `${userData.firstName} ${userData.lastName}`;
      }

      return "";
    });

    function portPages(ports) {
      const perPage = 48;

      if (ports.length > perPage) {
        // 108 / 48 = 3 pages
        // page 0 = 0-47
        // page 1 = 48-95
        // page 2 = 96-107
        const pageCount = Math.ceil(ports.length / perPage);
        const pages = [];
        for (let i = 0; i < pageCount; i++) {
          //                         [0,   48)
          //                         [48,   96)
          //                         [96,   144)
          pages.push(ports.slice(48 * i, 48 * (i + 1)));
        }
        console.log(pages);
        return pages;
      } else {
        return [ports];
      }
    }

    loading.value = true;
    Promise.all([
      root.$store.dispatch("portMapping/fetchCoreSwitches", props.siteDns),
      root.$store.dispatch("portMapping/fetchEdgeSwitches", props.siteDns),
      root.$store.dispatch("hotel/getHotelByDns", props.siteDns)
    ]).then(values => {
      loading.value = false;
      coreSwitches.value = root.$store.getters["portMapping/coreSwitches"];
      edgeSwitches.value = root.$store.getters["portMapping/edgeSwitches"];
      hotel.value = values[2];
    });
    return {
      portPages,
      coreSwitches,
      edgeSwitches,
      loading,
      hotelName,
      userName,
      currentDate
    };
  }
});
</script>

<style scoped></style>
